// library imports
import React, { useEffect, useState } from 'react';
import { Checkbox, Row, Col, Button, Space, Menu } from 'antd';
import styled from 'styled-components/macro';

// components
import CreateGlossaryEntityModal from '../entity/shared/EntityDropdown/CreateGlossaryEntityModal';
import BulkLoadEntityModal from '../entity/shared/EntityDropdown/BulkLoadEntityModal';
import TabToolbar from '../entity/shared/components/styled/TabToolbar';
import EmptyGlossarySection from './EmptyGlossarySection';
import GlossaryEntitiesPath from './GlossaryEntitiesPath';
import GlossaryEntitiesList from './GlossaryEntitiesList';
import { Notification } from '../shared/Notification';
import { Message } from '../shared/Message';

// services
import {
    useGetRootGlossaryNodesQuery,
    useGetRootGlossaryTermsQuery,
    useGetEntityImportValidationMetadataQuery,
} from '../../graphql/glossary.generated';
import { useGlossaryEntityData } from '../entity/shared/GlossaryEntityContext';
import { useExportEntityMutation } from '../../graphql/mutations.generated';
import { BrowseDropDown, BrowseMenu } from '../browse/BrowseResultActions';
import { sortGlossaryTerms } from '../entity/glossaryTerm/utils';
import { sortGlossaryNodes } from '../entity/glossaryNode/utils';
import { useUserContext } from '../context/useUserContext';
import { useEntityRegistry } from '../useEntityRegistry';
import { EntityType, EntityImportValidationMetadataInput } from '../../types.generated';
import {
    BUSINESS_GLOSSARY_CREATE_TERM_ID,
    BUSINESS_GLOSSARY_CREATE_TERM_GROUP_ID,
} from '../onboarding/config/BusinessGlossaryOnboardingConfig';

// svg imports
import { ReactComponent as DownloadOutlined } from '../../images/downloaded-outline.svg';
import { ReactComponent as PlusOutlined } from '../../images/add-outline.svg';
import { ReactComponent as UploadOutlined } from '../../images/upload.svg';
import { ReactComponent as MoreAction } from '../../images/more-vert.svg';

const GREY_COLOR = '#5F767C';

export const HeaderWrapper = styled(TabToolbar)`
    padding: 12px 24px;
    height: auto;
`;

const GlossaryWrapper = styled(Row)`
    flex: 1;
`;

const ButtonWrapper = styled.div`
    gap: 8px;
    display: flex;
    margin-right: 8px;
    align-items: center;
`;

// const GlossaryWrapper = styled.div`
//     display: flex;
//     flex: 1;
//     max-height: inherit;
// `;

// const MainContentWrapper = styled.div`
//     display: flex;
//     flex: 1;
//     flex-direction: column;
// `;

export const MAX_BROWSER_WIDTH = 500;
export const MIN_BROWSWER_WIDTH = 200;

const input: EntityImportValidationMetadataInput = {
    entityType: 'glossaryTerm',
};

function BusinessGlossaryPage() {
    // const [browserWidth, setBrowserWidth] = useState(window.innerWidth * 0.2);
    const {
        data: termsData,
        refetch: refetchForTerms,
        loading: termsLoading,
        error: termsError,
    } = useGetRootGlossaryTermsQuery();

    const { data: headers } = useGetEntityImportValidationMetadataQuery({ variables: { input } });

    const {
        data: nodesData,
        refetch: refetchForNodes,
        loading: nodesLoading,
        error: nodesError,
    } = useGetRootGlossaryNodesQuery();
    const entityRegistry = useEntityRegistry();
    const { setEntityData } = useGlossaryEntityData();

    useEffect(() => {
        setEntityData(null);
    }, [setEntityData]);

    const terms = termsData?.getRootGlossaryTerms?.terms.sort((termA, termB) =>
        sortGlossaryTerms(entityRegistry, termA, termB),
    );
    const nodes = nodesData?.getRootGlossaryNodes?.nodes.sort((nodeA, nodeB) =>
        sortGlossaryNodes(entityRegistry, nodeA, nodeB),
    );

    const hasTermsOrNodes = !!nodes?.length || !!terms?.length;

    const [isBulkLoadModalVisible, setIsBulkLoadModalVisible] = useState(false);
    const [isCreateTermModalVisible, setIsCreateTermModalVisible] = useState(false);
    const [isCreateNodeModalVisible, setIsCreateNodeModalVisible] = useState(false);
    const [isCsvExporting, setIsCsvExporting] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [exportEntity, setExportEntity]: any = useState(null);

    const [exportEntityMutation] = useExportEntityMutation();

    const user = useUserContext();
    const canManageGlossaries = user?.platformPrivileges?.manageGlossaries;

    /**
     * Handles the click event on the select all checkbox.
     * Updates the selectedItems state to include all glossary name if the select all checkbox is checked,
     * or clears the selectedItems state if the select all checkbox is unchecked.
     *
     *  * @param event - The click event object.
     */
    const handleSelectAll = (e) => {
        const allItems = [...(nodes || []), ...(terms || [])].map((item) => item.urn);
        setSelectedItems(e.target.checked ? allItems : []);
    };

    // Check if all items are selected.
    const isAllSelected = () => {
        if (!nodes || !terms) {
            return false;
        }
        return selectedItems.length === nodes.length + terms.length;
    };

    /**
     * Handles the export of the selected glossary to a CSV file.
     * Calls the exportEntityMutation to export the selected glossary to a CSV file.
     * Displays a notification message to the user once the export is completed.
     * Clears the selectedItems state and sets the isAllSelected state to false.
     */
    const exportCsv = () => {
        const glossaryNodeUrns = nodes?.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);
        const glossaryTermUrns = terms?.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);

        setIsCsvExporting(true);
        setExportEntity(null);

        exportEntityMutation({
            variables: {
                input: {
                    glossaryTerm: {
                        glossaryNodeUrns: glossaryNodeUrns || [],
                        glossaryTermUrns: glossaryTermUrns || [],
                    },
                    entityType: 'glossaryTerm',
                },
            },
        })
            .then((res = {}) => {
                const { data } = res;
                setExportEntity(data?.exportEntity);
                setSelectedItems([]);
            })
            .finally(() => setIsCsvExporting(false));
    };

    return (
        <>
            {exportEntity && <Notification exportEntity={exportEntity} />}

            {isCsvExporting && <Message type="loading" content="Loading..." style={{ marginTop: '10%' }} />}

            <GlossaryWrapper wrap={false} className="f-mt-2-xs">
                {(termsLoading || nodesLoading) && (
                    <Message type="loading" content="Loading Glossary..." style={{ marginTop: '10%' }} />
                )}
                {(termsError || nodesError) && (
                    <Message type="error" content="Failed to load glossary! An unexpected error occurred." />
                )}
                {/* <Col flex="228px" className="pr-2">
                    <Card style={{ height: '100%' }}>
                        <GlossarySearch />
                        <GlossaryBrowser rootNodes={nodes} rootTerms={terms} />
                    </Card>
                </Col> */}
                {/* <ProfileSidebarResizer
                    setSidePanelWidth={(width) =>
                        setBrowserWidth(Math.min(Math.max(width, MIN_BROWSWER_WIDTH), MAX_BROWSER_WIDTH))
                    }
                    initialSize={browserWidth}
                    isSidebarOnLeft
                /> */}
                <Col flex={1}>
                    <GlossaryEntitiesPath />
                    <HeaderWrapper>
                        <Space size="middle" align="center">
                            <Checkbox
                                checked={isAllSelected()}
                                disabled={termsLoading || nodesLoading}
                                onChange={handleSelectAll}
                            >
                                Select All
                            </Checkbox>
                        </Space>
                        <Row>
                            <ButtonWrapper>
                                <Button
                                    type="text"
                                    aria-label="Bulk Load"
                                    disabled={!canManageGlossaries}
                                    onClick={() => setIsBulkLoadModalVisible(true)}
                                >
                                    <UploadOutlined color={GREY_COLOR} />{' '}
                                    <span className="f-color-gray-s100">Bulk Load </span>
                                </Button>
                                <Button
                                    type="text"
                                    aria-label="Add Term"
                                    data-testid="add-term-button"
                                    disabled={!canManageGlossaries}
                                    id={BUSINESS_GLOSSARY_CREATE_TERM_ID}
                                    onClick={() => setIsCreateTermModalVisible(true)}
                                >
                                    <PlusOutlined color={GREY_COLOR} style={{ width: 12 }} />{' '}
                                    <span className="f-color-gray-s100">Add Term</span>
                                </Button>
                                <Button
                                    type="text"
                                    aria-label="Add Term Group"
                                    disabled={!canManageGlossaries}
                                    data-testid="add-term-group-button"
                                    id={BUSINESS_GLOSSARY_CREATE_TERM_GROUP_ID}
                                    onClick={() => setIsCreateNodeModalVisible(true)}
                                >
                                    <PlusOutlined color={GREY_COLOR} style={{ width: 12 }} />{' '}
                                    <span className="f-color-gray-s100">Add Term Group</span>
                                </Button>
                            </ButtonWrapper>
                            <BrowseDropDown
                                overlay={
                                    <BrowseMenu>
                                        <Menu.Item
                                            key="1"
                                            onClick={exportCsv}
                                            tabIndex={0}
                                            disabled={selectedItems.length === 0}
                                            className="f-color-gray-s100"
                                            icon={<DownloadOutlined style={{ width: 16 }} color="#5F767C" />}
                                        >
                                            Export
                                        </Menu.Item>
                                    </BrowseMenu>
                                }
                                trigger={['click']}
                            >
                                <Row align="middle" style={{ gap: 8 }} tabIndex={0}>
                                    <MoreAction
                                        data-testid="entity-header-dropdown"
                                        width={12}
                                        height={12}
                                        color={GREY_COLOR}
                                    />
                                    <span className="f-color-gray-s100">Actions</span>
                                </Row>
                            </BrowseDropDown>
                        </Row>
                    </HeaderWrapper>
                    {hasTermsOrNodes && (
                        <GlossaryEntitiesList
                            nodes={nodes || []}
                            terms={terms || []}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                        />
                    )}
                    {!(termsLoading || nodesLoading) && !hasTermsOrNodes && (
                        <EmptyGlossarySection
                            title="Empty Glossary"
                            description="Create Terms and Term Groups to organize data assets using a shared vocabulary."
                            refetchForTerms={refetchForTerms}
                            refetchForNodes={refetchForNodes}
                        />
                    )}
                </Col>
            </GlossaryWrapper>
            {isBulkLoadModalVisible && (
                <BulkLoadEntityModal
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsBulkLoadModalVisible(false)}
                    expectedHeadersFromApi={
                        headers?.getEntityImportValidationMetadata?.validationMetadata.map((header) => ({
                            ...header,
                            sampleRows: header.sampleRows.map((row) => row.map((cell) => cell || '')),
                        })) || []
                    }
                />
            )}
            {isCreateTermModalVisible && (
                <CreateGlossaryEntityModal
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsCreateTermModalVisible(false)}
                    refetchData={refetchForTerms}
                />
            )}
            {isCreateNodeModalVisible && (
                <CreateGlossaryEntityModal
                    entityType={EntityType.GlossaryNode}
                    onClose={() => setIsCreateNodeModalVisible(false)}
                    refetchData={refetchForNodes}
                />
            )}
        </>
    );
}

export default BusinessGlossaryPage;
