import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Row, Col, Tag, Dropdown, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ReactComponent as IconMetaExplorer } from '../../../../../images/icon-meta-explorer.svg';
import { ReactComponent as IconClose } from '../../../../../images/icon-close.svg';
import CustomTooltip from '../../components/common/CustomTooltip';
import CustomAvatar from '../../../../shared/avatar/CustomAvatar';

const CERTIFIED = 'Certified';
const UNDER_REVIEW = 'Under_Review';

const CloseButton = styled(Button)`
    &.ant-btn.f-btn-action {
        height: 24px;
        width: 24px;

        svg {
            height: 12px;
            width: 12px;
        }
    }
`;

const MetaButton = styled(Button)`
    height: 32px;
    width: 32px;
    justify-content: center;

    svg {
        height: 20px !important;
        width: 20px !important;
    }
`;

export const getStatusClass = (status) => {
    return (
        {
            [UNDER_REVIEW]: 'base-tag-outline',
            [CERTIFIED]: 'ant-tag-success',
        }[status] || 'f-tag-gray-outline'
    );
};

const renderTag = (condition, title) => (
    <Row gutter={4} align="middle">
        <Col span={8} className="f-metadata-label">
            {title}
        </Col>
        <Col>
            <Tag className={`label-tag ${condition ? 'ant-tag-success f-tag-borderless' : 'des-tag'} ma-0`}>
                {condition ? <CheckOutlined /> : <CloseOutlined />}
            </Tag>
        </Col>
    </Row>
);

type MetaDataPopupProps = {
    metaData: any;
    urnMapper: any;
};

const MetaDataPopup = ({ metaData, urnMapper }: MetaDataPopupProps) => {
    const { governanceFlags, governanceStatus, name, description, subType, domains, dataProducts, owners, urn } =
        metaData;
    const isPII = governanceFlags?.includes('PII');
    const isCDE = governanceFlags?.includes('CDE');
    const breadcrumb = urnMapper[urn]?.map((item) => `${item.properties.name} / `);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    return (
        <Dropdown
            trigger={['click']}
            open={dropdownVisible}
            onOpenChange={(visible) => setDropdownVisible(visible)}
            placement="bottomRight"
            overlay={
                <Card className="f-metadata-explorer-card">
                    <Row wrap={false} gutter={8} align="top">
                        <Col flex="auto">
                            <div className="f-metadata-title">{name}</div>
                            <div className="f-metadata-subtitle f-color-dark-black-s50">{breadcrumb}</div>
                        </Col>
                        <Col flex="none" className="px-0">
                            <CloseButton
                                type="text"
                                icon={<IconClose />}
                                className="f-btn-action"
                                title="Close Metadata Explorer"
                                aria-label="Close Metadata Explorer"
                                onClick={() => setDropdownVisible(!dropdownVisible)}
                            />
                        </Col>
                    </Row>

                    {[
                        ['Description', description],
                        ['SubType', subType],
                    ].map(([label, value]) => (
                        <Row gutter={4} align="middle" key={label}>
                            <Col span={8} className="f-metadata-label">
                                {label}
                            </Col>
                            <Col span={16} className="f-metadata-value">
                                <Typography.Paragraph
                                    className="mb-0"
                                    ellipsis={{
                                        rows: 4,
                                        expandable: true,
                                        symbol: 'see more',
                                    }}
                                >
                                    {value || '-'}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    ))}

                    <Row gutter={4} align="middle">
                        <Col span={8} className="f-metadata-label">
                            Governance Status
                        </Col>
                        <Col span={16}>
                            {governanceStatus ? (
                                <Tag className={`label-tag ${getStatusClass(governanceStatus)} ma-0`}>
                                    {governanceStatus.replace(/_/g, ' ')}
                                </Tag>
                            ) : (
                                '-'
                            )}
                        </Col>
                    </Row>

                    {renderTag(isPII, 'PII Flag')}
                    {renderTag(isCDE, 'CDE Flag')}

                    <Row gutter={4} align="middle">
                        <Col span={8} className="f-metadata-label">
                            Domain
                        </Col>
                        <Col flex="f-metadata-value">{urnMapper[domains?.[0]]}</Col>
                    </Row>

                    <Row gutter={4} align="middle">
                        <Col span={8} className="f-metadata-label">
                            Data Product
                        </Col>
                        <Col flex="f-metadata-value">{urnMapper[dataProducts?.[0]]}</Col>
                        <Col flex="none">
                            <CustomTooltip items={dataProducts} itemMapper={urnMapper} />
                        </Col>
                    </Row>

                    <Row gutter={4} align="middle">
                        <Col span={8} className="f-metadata-label">
                            Owners
                        </Col>
                        <Col flex="f-metadata-value">
                            <CustomAvatar name={urnMapper[owners?.[0]]} size={24} />
                        </Col>
                        <Col flex="none">
                            <CustomTooltip items={owners} itemMapper={urnMapper} />
                        </Col>
                    </Row>
                </Card>
            }
        >
            <MetaButton
                type="text"
                size="middle"
                icon={<IconMetaExplorer />}
                className="icon-button ma-0"
                title="Show Metadata Explorer"
                aria-label="Show Metadata Explorer"
                onClick={() => setDropdownVisible(!dropdownVisible)}
            />
        </Dropdown>
    );
};

export default MetaDataPopup;
