import { Select, SelectProps, Spin } from 'antd';
import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetSearchResultsLazyQuery } from '../../graphql/search.generated';
import utilService from '../../utils/util.service';
import { Entity, EntityType } from '../../types.generated';
import { useEntityRegistry } from '../useEntityRegistry';

const { debounce } = utilService;

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

interface IEntityLookup extends SelectProps {
    filterFieldRef: any;
    type: EntityType;
    entityType?: EntityType;
    handleFieldRef: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EntityLookup = (props: IEntityLookup) => {
    const entityRegistry = useEntityRegistry();
    const [entitySearch, { data: searchData, loading }] = useGetSearchResultsLazyQuery();
    const entitySearchDebounce = debounce(entitySearch, 500);

    const entities = searchData?.search?.searchResults || [];

    const getSearchInput = (query: string, entityType?: EntityType) => ({
        type: entityType || EntityType.CorpUser,
        query: query || '*', // Ensuring default query '*'
        start: 0,
        count: 5,
    });

    useEffect(() => {
        entitySearch({
            variables: {
                input: getSearchInput('*', props.entityType),
            },
        });
    }, [entitySearch, props.entityType]);

    const onSearch = (text: string) => {
        entitySearchDebounce({
            variables: {
                input: getSearchInput(text, props.entityType),
            },
        });
    };

    const renderOptions = ({ entity }: { entity: Entity }) => {
        const displayName = entityRegistry.getDisplayName(entity.type, entity);

        return {
            label: <div>{displayName}</div>,
            value: entity.urn,
            key: entity.urn,
        };
    };

    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Select the Data Product"
            allowClear
            showSearch
            filterOption={false}
            ref={props?.filterFieldRef}
            onSearch={onSearch}
            onKeyDown={props?.handleFieldRef}
            className="f-filter-dropdown-field"
            loading={loading}
            options={entities?.map(renderOptions)}
            notFoundContent={loading ? <Spin indicator={antIcon} style={{ width: '100%' }} /> : null}
            {...props}
        />
    );
};

export default EntityLookup;
