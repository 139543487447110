/* eslint-disable */
import * as Types from '../types.generated';

import {
    StructuredPropertiesFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    PlatformFieldsFragment,
    EntityDataProduct_AccessTokenMetadata_Fragment,
    EntityDataProduct_Assertion_Fragment,
    EntityDataProduct_Chart_Fragment,
    EntityDataProduct_Container_Fragment,
    EntityDataProduct_CorpGroup_Fragment,
    EntityDataProduct_CorpUser_Fragment,
    EntityDataProduct_Dashboard_Fragment,
    EntityDataProduct_DataFlow_Fragment,
    EntityDataProduct_DataHubPolicy_Fragment,
    EntityDataProduct_DataHubRole_Fragment,
    EntityDataProduct_DataHubView_Fragment,
    EntityDataProduct_DataJob_Fragment,
    EntityDataProduct_DataPlatform_Fragment,
    EntityDataProduct_DataPlatformInstance_Fragment,
    EntityDataProduct_DataProcessInstance_Fragment,
    EntityDataProduct_DataProduct_Fragment,
    EntityDataProduct_DataTypeEntity_Fragment,
    EntityDataProduct_Dataset_Fragment,
    EntityDataProduct_Domain_Fragment,
    EntityDataProduct_EntityTypeEntity_Fragment,
    EntityDataProduct_GlossaryNode_Fragment,
    EntityDataProduct_GlossaryTerm_Fragment,
    EntityDataProduct_MlFeature_Fragment,
    EntityDataProduct_MlFeatureTable_Fragment,
    EntityDataProduct_MlModel_Fragment,
    EntityDataProduct_MlModelGroup_Fragment,
    EntityDataProduct_MlPrimaryKey_Fragment,
    EntityDataProduct_Notebook_Fragment,
    EntityDataProduct_OwnershipTypeEntity_Fragment,
    EntityDataProduct_Post_Fragment,
    EntityDataProduct_QueryEntity_Fragment,
    EntityDataProduct_Role_Fragment,
    EntityDataProduct_SchemaFieldEntity_Fragment,
    EntityDataProduct_StructuredPropertyEntity_Fragment,
    EntityDataProduct_Tag_Fragment,
    EntityDataProduct_Test_Fragment,
    EntityDataProduct_VersionedDataset_Fragment,
    NonRecursiveDataFlowFieldsFragment,
    DeprecationFieldsFragment,
    EmbedFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    ParentContainersFieldsFragment,
    BrowsePathV2FieldsFragment,
    InputFieldsFieldsFragment,
    EntityContainerFragment,
    TypeOfRelationsFieldsFragment,
    ParentNodesFieldsFragment,
    GlossaryNodeFragment,
    GlossaryTermFragment,
    ParentDomainsFieldsFragment,
    DomainEntitiesFieldsFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
    SchemaMetadataFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    EntityDisplayNameFields_AccessTokenMetadata_Fragment,
    EntityDisplayNameFields_Assertion_Fragment,
    EntityDisplayNameFields_Chart_Fragment,
    EntityDisplayNameFields_Container_Fragment,
    EntityDisplayNameFields_CorpGroup_Fragment,
    EntityDisplayNameFields_CorpUser_Fragment,
    EntityDisplayNameFields_Dashboard_Fragment,
    EntityDisplayNameFields_DataFlow_Fragment,
    EntityDisplayNameFields_DataHubPolicy_Fragment,
    EntityDisplayNameFields_DataHubRole_Fragment,
    EntityDisplayNameFields_DataHubView_Fragment,
    EntityDisplayNameFields_DataJob_Fragment,
    EntityDisplayNameFields_DataPlatform_Fragment,
    EntityDisplayNameFields_DataPlatformInstance_Fragment,
    EntityDisplayNameFields_DataProcessInstance_Fragment,
    EntityDisplayNameFields_DataProduct_Fragment,
    EntityDisplayNameFields_DataTypeEntity_Fragment,
    EntityDisplayNameFields_Dataset_Fragment,
    EntityDisplayNameFields_Domain_Fragment,
    EntityDisplayNameFields_EntityTypeEntity_Fragment,
    EntityDisplayNameFields_GlossaryNode_Fragment,
    EntityDisplayNameFields_GlossaryTerm_Fragment,
    EntityDisplayNameFields_MlFeature_Fragment,
    EntityDisplayNameFields_MlFeatureTable_Fragment,
    EntityDisplayNameFields_MlModel_Fragment,
    EntityDisplayNameFields_MlModelGroup_Fragment,
    EntityDisplayNameFields_MlPrimaryKey_Fragment,
    EntityDisplayNameFields_Notebook_Fragment,
    EntityDisplayNameFields_OwnershipTypeEntity_Fragment,
    EntityDisplayNameFields_Post_Fragment,
    EntityDisplayNameFields_QueryEntity_Fragment,
    EntityDisplayNameFields_Role_Fragment,
    EntityDisplayNameFields_SchemaFieldEntity_Fragment,
    EntityDisplayNameFields_StructuredPropertyEntity_Fragment,
    EntityDisplayNameFields_Tag_Fragment,
    EntityDisplayNameFields_Test_Fragment,
    EntityDisplayNameFields_VersionedDataset_Fragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    StructuredPropertiesFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    PlatformFieldsFragmentDoc,
    EntityDataProductFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    EmbedFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    BrowsePathV2FieldsFragmentDoc,
    InputFieldsFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    TypeOfRelationsFieldsFragmentDoc,
    ParentNodesFieldsFragmentDoc,
    GlossaryNodeFragmentDoc,
    GlossaryTermFragmentDoc,
    ParentDomainsFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    EntityDisplayNameFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type UpsertStructuredPropertiesMutationVariables = Types.Exact<{
    input: Types.UpsertStructuredPropertiesInput;
}>;

export type UpsertStructuredPropertiesMutation = { __typename?: 'Mutation' } & {
    upsertStructuredProperties: { __typename?: 'StructuredProperties' } & {
        properties?: Types.Maybe<
            Array<{ __typename?: 'StructuredPropertiesEntry' } & StructuredPropertiesFieldsFragment>
        >;
    };
};

export type RemoveStructuredPropertiesMutationVariables = Types.Exact<{
    input: Types.RemoveStructuredPropertiesInput;
}>;

export type RemoveStructuredPropertiesMutation = { __typename?: 'Mutation' } & {
    removeStructuredProperties: { __typename?: 'StructuredProperties' } & {
        properties?: Types.Maybe<
            Array<
                { __typename?: 'StructuredPropertiesEntry' } & {
                    structuredProperty: { __typename?: 'StructuredPropertyEntity' } & Pick<
                        Types.StructuredPropertyEntity,
                        'urn'
                    >;
                }
            >
        >;
    };
};

export type GetApplicableStructuredPropertiesQueryVariables = Types.Exact<{
    input: Types.ApplicableStructuredPropertiesListInput;
}>;

export type GetApplicableStructuredPropertiesQuery = { __typename?: 'Query' } & {
    getApplicableStructuredProperties?: Types.Maybe<
        { __typename?: 'ApplicableStructuredPropertiesList' } & {
            structuredProperties?: Types.Maybe<
                Array<
                    { __typename: 'StructuredPropertyEntity' } & Pick<
                        Types.StructuredPropertyEntity,
                        'exists' | 'type' | 'urn'
                    > & {
                            definition: {
                                __typename: 'StructuredPropertyDefinition';
                            } & StructurePropertiesDefinationFragment;
                        }
                >
            >;
        }
    >;
};

export type StructurePropertiesDefinationFragment = { __typename?: 'StructuredPropertyDefinition' } & Pick<
    Types.StructuredPropertyDefinition,
    'isActive' | 'displayName' | 'qualifiedName' | 'description' | 'cardinality' | 'immutable'
> & {
        valueType: { __typename: 'DataTypeEntity' } & {
            info: { __typename: 'DataTypeInfo' } & Pick<Types.DataTypeInfo, 'type' | 'displayName'>;
        };
        entityTypes: Array<
            { __typename: 'EntityTypeEntity' } & {
                info: { __typename: 'EntityTypeInfo' } & Pick<Types.EntityTypeInfo, 'type'>;
            }
        >;
        typeQualifier?: Types.Maybe<
            { __typename: 'TypeQualifier' } & {
                allowedTypes?: Types.Maybe<
                    Array<
                        { __typename: 'EntityTypeEntity' } & Pick<Types.EntityTypeEntity, 'urn' | 'type'> & {
                                info: { __typename: 'EntityTypeInfo' } & Pick<
                                    Types.EntityTypeInfo,
                                    'type' | 'displayName'
                                >;
                            }
                    >
                >;
            }
        >;
        allowedValues?: Types.Maybe<
            Array<
                { __typename: 'AllowedValue' } & Pick<Types.AllowedValue, 'description'> & {
                        value:
                            | ({ __typename: 'StringValue' } & Pick<Types.StringValue, 'stringValue'>)
                            | ({ __typename: 'NumberValue' } & Pick<Types.NumberValue, 'numberValue'>);
                    }
            >
        >;
        created?: Types.Maybe<
            { __typename?: 'ResolvedAuditStamp' } & Pick<Types.ResolvedAuditStamp, 'time'> & {
                    actor?: Types.Maybe<{ __typename?: 'CorpUser' } & OwnersFragment>;
                }
        >;
        lastModified?: Types.Maybe<
            { __typename?: 'ResolvedAuditStamp' } & Pick<Types.ResolvedAuditStamp, 'time'> & {
                    actor?: Types.Maybe<{ __typename?: 'CorpUser' } & OwnersFragment>;
                }
        >;
        entitiesWithPlatforms?: Types.Maybe<
            { __typename?: 'EntitiesWithPlatformsResult' } & {
                container?: Types.Maybe<Array<{ __typename?: 'DataPlatform' } & PlatformFieldsFragment>>;
                dataset?: Types.Maybe<Array<{ __typename?: 'DataPlatform' } & PlatformFieldsFragment>>;
            }
        >;
    };

export type OwnersFragment = { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type' | 'username'> & {
        info?: Types.Maybe<
            { __typename: 'CorpUserInfo' } & Pick<
                Types.CorpUserInfo,
                'active' | 'displayName' | 'title' | 'email' | 'firstName' | 'lastName' | 'fullName'
            >
        >;
    };

export const OwnersFragmentDoc = gql`
    fragment owners on CorpUser {
        urn
        type
        username
        info {
            active
            displayName
            title
            email
            firstName
            lastName
            fullName
            __typename
        }
    }
`;
export const StructurePropertiesDefinationFragmentDoc = gql`
    fragment structurePropertiesDefination on StructuredPropertyDefinition {
        isActive
        displayName
        qualifiedName
        description
        cardinality
        immutable
        valueType {
            info {
                type
                displayName
                __typename
            }
            __typename
        }
        entityTypes {
            info {
                type
                __typename
            }
            __typename
        }
        typeQualifier {
            allowedTypes {
                urn
                type
                info {
                    type
                    displayName
                    __typename
                }
                __typename
            }
            __typename
        }
        allowedValues {
            value {
                ... on StringValue {
                    stringValue
                    __typename
                }
                ... on NumberValue {
                    numberValue
                    __typename
                }
                __typename
            }
            description
            __typename
        }
        created {
            actor {
                ...owners
            }
            time
        }
        lastModified {
            time
            actor {
                ...owners
            }
        }
        entitiesWithPlatforms {
            container {
                ...platformFields
            }
            dataset {
                ...platformFields
            }
        }
    }
    ${OwnersFragmentDoc}
    ${PlatformFieldsFragmentDoc}
`;
export const UpsertStructuredPropertiesDocument = gql`
    mutation upsertStructuredProperties($input: UpsertStructuredPropertiesInput!) {
        upsertStructuredProperties(input: $input) {
            properties {
                ...structuredPropertiesFields
            }
        }
    }
    ${StructuredPropertiesFieldsFragmentDoc}
`;
export type UpsertStructuredPropertiesMutationFn = Apollo.MutationFunction<
    UpsertStructuredPropertiesMutation,
    UpsertStructuredPropertiesMutationVariables
>;

/**
 * __useUpsertStructuredPropertiesMutation__
 *
 * To run a mutation, you first call `useUpsertStructuredPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStructuredPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStructuredPropertiesMutation, { data, loading, error }] = useUpsertStructuredPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStructuredPropertiesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpsertStructuredPropertiesMutation,
        UpsertStructuredPropertiesMutationVariables
    >,
) {
    return Apollo.useMutation<UpsertStructuredPropertiesMutation, UpsertStructuredPropertiesMutationVariables>(
        UpsertStructuredPropertiesDocument,
        baseOptions,
    );
}
export type UpsertStructuredPropertiesMutationHookResult = ReturnType<typeof useUpsertStructuredPropertiesMutation>;
export type UpsertStructuredPropertiesMutationResult = Apollo.MutationResult<UpsertStructuredPropertiesMutation>;
export type UpsertStructuredPropertiesMutationOptions = Apollo.BaseMutationOptions<
    UpsertStructuredPropertiesMutation,
    UpsertStructuredPropertiesMutationVariables
>;
export const RemoveStructuredPropertiesDocument = gql`
    mutation removeStructuredProperties($input: RemoveStructuredPropertiesInput!) {
        removeStructuredProperties(input: $input) {
            properties {
                structuredProperty {
                    urn
                }
            }
        }
    }
`;
export type RemoveStructuredPropertiesMutationFn = Apollo.MutationFunction<
    RemoveStructuredPropertiesMutation,
    RemoveStructuredPropertiesMutationVariables
>;

/**
 * __useRemoveStructuredPropertiesMutation__
 *
 * To run a mutation, you first call `useRemoveStructuredPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStructuredPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStructuredPropertiesMutation, { data, loading, error }] = useRemoveStructuredPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStructuredPropertiesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveStructuredPropertiesMutation,
        RemoveStructuredPropertiesMutationVariables
    >,
) {
    return Apollo.useMutation<RemoveStructuredPropertiesMutation, RemoveStructuredPropertiesMutationVariables>(
        RemoveStructuredPropertiesDocument,
        baseOptions,
    );
}
export type RemoveStructuredPropertiesMutationHookResult = ReturnType<typeof useRemoveStructuredPropertiesMutation>;
export type RemoveStructuredPropertiesMutationResult = Apollo.MutationResult<RemoveStructuredPropertiesMutation>;
export type RemoveStructuredPropertiesMutationOptions = Apollo.BaseMutationOptions<
    RemoveStructuredPropertiesMutation,
    RemoveStructuredPropertiesMutationVariables
>;
export const GetApplicableStructuredPropertiesDocument = gql`
    query getApplicableStructuredProperties($input: ApplicableStructuredPropertiesListInput!) {
        getApplicableStructuredProperties(input: $input) {
            structuredProperties {
                exists
                type
                urn
                definition {
                    ...structurePropertiesDefination
                    __typename
                }
                __typename
            }
        }
    }
    ${StructurePropertiesDefinationFragmentDoc}
`;

/**
 * __useGetApplicableStructuredPropertiesQuery__
 *
 * To run a query within a React component, call `useGetApplicableStructuredPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicableStructuredPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicableStructuredPropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApplicableStructuredPropertiesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetApplicableStructuredPropertiesQuery,
        GetApplicableStructuredPropertiesQueryVariables
    >,
) {
    return Apollo.useQuery<GetApplicableStructuredPropertiesQuery, GetApplicableStructuredPropertiesQueryVariables>(
        GetApplicableStructuredPropertiesDocument,
        baseOptions,
    );
}
export function useGetApplicableStructuredPropertiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetApplicableStructuredPropertiesQuery,
        GetApplicableStructuredPropertiesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetApplicableStructuredPropertiesQuery, GetApplicableStructuredPropertiesQueryVariables>(
        GetApplicableStructuredPropertiesDocument,
        baseOptions,
    );
}
export type GetApplicableStructuredPropertiesQueryHookResult = ReturnType<
    typeof useGetApplicableStructuredPropertiesQuery
>;
export type GetApplicableStructuredPropertiesLazyQueryHookResult = ReturnType<
    typeof useGetApplicableStructuredPropertiesLazyQuery
>;
export type GetApplicableStructuredPropertiesQueryResult = Apollo.QueryResult<
    GetApplicableStructuredPropertiesQuery,
    GetApplicableStructuredPropertiesQueryVariables
>;
