import React, { ChangeEventHandler, useState } from 'react';
import { Button, Modal, message, Form, Row, Checkbox, Space, Tooltip, Select, Spin } from 'antd';
import { FileFilled, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import axios from 'axios';

import VerificationStatus from './VerificationStatus';

import { EXPECTED_HEADERS, DOWNLOAD_SAMPLE_BUTTONS_CONFIG as downloadConfigs, CSV, EXCEL } from '../constants';
import { generateSampleFile, validateHeadersXlsx } from './utils';
import utilService from '../../../../utils/util.service';
import ErrorLogModal from './ErrorLogModal';
import { EntityXlsxExpectedSheetHeader } from '../types';

const { Option } = Select;

interface Props {
    onClose: () => void;
    expectedHeadersFromApi: EntityXlsxExpectedSheetHeader[];
}

enum FileStatus {
    VERIFIED = 'verified',
    FAILED = 'failed',
    UPLOAD = 'upload',
}

function DatasetBulkLoadEntityModal(props: Props) {
    const [form] = Form.useForm();
    const { onClose, expectedHeadersFromApi } = props;

    const [isFormValid, setIsFormValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [override, setOverride] = useState(false);

    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [selectedType, setSelectedType] = useState<string>('dataset');
    const isDataset = selectedType === 'dataset';
    const [errorLogs, setErrorLogs]: any = useState();
    const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState<boolean>();

    function onBulkImport(values) {
        const formData = new FormData();
        const isDatasetSchema = values.datasetSchemaUpdate === 'dataset_schema';

        formData.append('file', values.file);
        formData.append('datasetSchemaUpdate', String(isDatasetSchema));
        formData.append('override', String(override));

        setIsLoading(true);

        axios
            .post('/api/bulkDatasetUpdate', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-EDC-XSRF-TOKEN': utilService.getXsrfToken(),
                },
            })
            .then((res: any) => {
                message.success(res?.data?.STATUS || 'Uploaded Successfully');
                onClose();
            })
            .catch((error) => {
                message.error(error?.response?.data?.STATUS || error?.message || 'Something went wrong');
            })
            .finally(() => setIsLoading(false));
    }

    /* This method allow us to validate the header of the csv files */
    const validateXlsxHeaders = (csvData, expectedSheets) => {
        const results: any = validateHeadersXlsx(csvData, expectedSheets);

        return {
            isValid: results?.every((result) => result.isValid),
            xlsxLogs: results?.filter((result) => !result.isValid),
        };
    };

    const validateCsvHeaders = (csvData, expectedHeaders) => {
        const lines = csvData.split('\n');
        const headerRow = lines[0].trim().split(',');

        const missingHeaders = expectedHeaders.filter((header) => !headerRow.includes(header));
        const unexpectedHeaders = headerRow.filter((header) => !expectedHeaders.includes(header));

        if (missingHeaders.length === 0 && unexpectedHeaders.length === 0) {
            return true;
        }

        setErrorLogs({ missingHeaders, unexpectedHeaders });
        return false;
    };

    const validateHeaders = (csvData, expectedHeaders, isXlsx) => {
        if (isXlsx) {
            const { isValid, xlsxLogs } = validateXlsxHeaders(csvData, expectedHeaders);

            if (!isValid) {
                setErrorLogs({ xlsxLogs });
            }

            return isValid;
        }
        return validateCsvHeaders(csvData, expectedHeaders);
    };

    /**
     * This method is used to read the CSV file as text format.
     * @param {object} file - The CSV file object.
     * @returns {Promise} - Returns a promise.
     */
    function readFileAsText(file, isXlsx) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            setIsFileProcessing(true);

            reader.onload = (e) => {
                try {
                    if (isXlsx) {
                        const data = new Uint8Array(e.target?.result as ArrayBuffer);
                        const workbook = XLSX.read(data, {
                            type: 'array',
                            sheetRows: 1,
                            cellFormula: false,
                            cellHTML: false,
                        });
                        resolve(workbook);
                    } else {
                        resolve(e.target?.result); // Resolve with CSV content as plain text
                    }
                    setIsFileProcessing(false);
                } catch (error) {
                    reject(new Error('Error processing the file'));
                } finally {
                    // Ensure that the file processing state is reset, regardless of success or failure
                    setIsFileProcessing(false);
                }
            };

            reader.onerror = () => reject(new Error('File reading failed'));

            if (isXlsx) {
                reader.readAsArrayBuffer(file); // Read as ArrayBuffer for XLSX
            } else {
                reader.readAsText(file); // Read as text for CSV
            }
        });
    }

    // Resets the header and show upload card
    const removeSelectedFile = (e) => {
        e.stopPropagation();
        form.resetFields(['file']);
    };

    /**
     * Render the upload card based on the status.
     * - If status is VERIFIED then it will show the verified card information
     * - If status is FAILED then it will show the failed card information
     * - If status is UPLOAD then it will show the default upload card
     * @returns {JSX.Element} The JSX element representing the upload card.
     */
    const renderUploadCard = (status) => {
        const verificationTxt = isFileProcessing ? 'Verifying' : 'Verification Successful';

        switch (status) {
            case FileStatus.VERIFIED:
                return ({ fileName }: { fileName: string }) => (
                    <>
                        <div className="f-container-title">{verificationTxt}</div>
                        <Row justify="center" align="middle">
                            <div>
                                <FileFilled style={{ color: '#1CA8DD' }} className="mr-2" />
                                {fileName}
                            </div>
                            {isFileProcessing ? (
                                <div className="ml-2">
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                </div>
                            ) : (
                                <Button type="text" onClick={removeSelectedFile}>
                                    Remove
                                </Button>
                            )}
                        </Row>
                    </>
                );
            case FileStatus.FAILED:
                return ({ fileName }: { fileName: string }) => (
                    <>
                        <VerificationStatus
                            selectedFile={fileName}
                            onRetry={removeSelectedFile}
                            setIsErrorLogModalVisible={setIsErrorLogModalVisible}
                        />
                    </>
                );
            default:
                return ({ onSelect }: { onSelect: ChangeEventHandler<HTMLInputElement> }) => (
                    <>
                        <div className="f-container-title">{`Drag & drop your csv ${
                            isDataset ? 'or xlsx' : ''
                        } here`}</div>
                        <div className="f-container-subtitle mt-2">We will verify it against the template first</div>
                        <Button className="f-btn-sec ma-0 mt-2">OR select File to Import</Button>
                        <input
                            type="file"
                            accept={isDataset ? '.csv, .xlsx' : '.csv'}
                            onChange={onSelect}
                            aria-label="CSV Bulk Import"
                        />
                    </>
                );
        }
    };

    const DragNDrop = (fieldProps) => {
        const { onChange, value } = fieldProps;

        const [error] = form.getFieldError('file');

        // Here, we get the status of the file to render the UI accordingly.
        let status;
        if (error && value) {
            status = error;
        } else if (value) {
            status = FileStatus.VERIFIED;
        } else {
            status = FileStatus.UPLOAD;
        }

        // Here, we get the rendered UI.
        const Render = renderUploadCard(status);

        // Here, we handle the drag and drop files
        const onDropHandler = (e) => {
            const file = e?.target?.files?.[0];
            if (file?.type?.includes?.('csv') || file?.name?.includes?.('.xlsx')) {
                onChange(file);
            }
        };

        return (
            <div className="f-file-upload-container">
                <div onDrop={onDropHandler}>
                    <Render fileName={value?.name ?? ''} onSelect={onDropHandler} />
                </div>
            </div>
        );
    };

    const createExcelWithMultipleSheets = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Loop through each sheet data and add to workbook dynamically
        expectedHeadersFromApi?.forEach(({ sheetName, sheetColumns, sampleRows }) => {
            const worksheet = XLSX.utils.aoa_to_sheet([sheetColumns, ...sampleRows]); // Create sheet from data
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Append sheet to workbook
        });

        // Generate Excel file and trigger download
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        generateSampleFile('dataset.xlsx', blob);
    };

    const downloadSample = () => {
        const { header, data, name } = EXPECTED_HEADERS[form.getFieldValue('datasetSchemaUpdate')];

        // Create the CSV Sample Data
        const csvData = [[header.join(',')], [data.join(',')]];

        // Create a Blob containing the CSV data
        const blob = new Blob([csvData.join('\n')], { type: 'text/csv' });

        generateSampleFile(name, blob);
    };

    // Handle the download sample based on the format
    const handleSampleDownload = (format) => {
        switch (format) {
            case CSV:
                downloadSample();
                break;
            case EXCEL:
                createExcelWithMultipleSheets();
                break;
            default:
                break;
        }
    };

    // File input validation logic
    const fileValidator = ({ getFieldValue }) => ({
        async validator(_, csvFile) {
            const isXlsx = csvFile?.name?.includes('.xlsx');

            try {
                // Here, we check if the file is empty, then return an error.
                if (!csvFile) {
                    return await Promise.reject(FileStatus.UPLOAD);
                }

                // Here, we get the expected headers.
                const expectedHeaders: string[] = isXlsx
                    ? expectedHeadersFromApi
                    : EXPECTED_HEADERS[getFieldValue('datasetSchemaUpdate')].header;

                // Here, we get the CSV file as text format.
                const csvData = await readFileAsText(csvFile, isXlsx);

                // Here, we validate the header from the expected headers.
                const result = validateHeaders(csvData, expectedHeaders, isXlsx);

                if (result) {
                    return await Promise.resolve(FileStatus.VERIFIED);
                }
                return await Promise.reject(FileStatus.FAILED);
            } catch (err) {
                return await Promise.reject(FileStatus.FAILED);
            }
        },
    });

    return (
        <>
            <Modal
                open
                title="Bulk Import"
                width={780}
                centered
                closable={false}
                onCancel={onClose}
                footer={
                    <>
                        <Button
                            form="importBulkForm"
                            type="primary"
                            htmlType="submit"
                            disabled={isFormValid || isFileProcessing}
                            loading={isLoading}
                        >
                            Import
                        </Button>

                        <Button onClick={onClose} type="default">
                            Cancel
                        </Button>
                    </>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark="optional"
                    name="importBulkForm"
                    onFinish={onBulkImport}
                    initialValues={{ file: null, datasetSchemaUpdate: 'dataset', override: false }}
                    onFieldsChange={() =>
                        setIsFormValid(form.getFieldsError().some((field) => field.errors.length > 0))
                    }
                >
                    {/* csv type */}
                    <Form.Item
                        name="datasetSchemaUpdate"
                        label={
                            <>
                                <span className="mr-1">Select CSV/Excel type</span>
                                <Tooltip
                                    overlayStyle={{ maxWidth: '200px' }}
                                    placement="top"
                                    title="The Excel type only allows to import multiple aspects from a dataset, including schema. The CSV type supports Dataset and Dataset Schema types in different CSV files."
                                    showArrow
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </>
                        }
                        required
                    >
                        <Select
                            placeholder="Select CSV/Excel type."
                            aria-label="Select CSV/Excel type."
                            onChange={(value) => setSelectedType(value)}
                            aria-hidden="true"
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                            <Option value="dataset" tabIndex={0}>
                                Dataset
                            </Option>
                            <Option value="dataset_schema" tabIndex={0}>
                                Dataset schema
                            </Option>
                        </Select>
                    </Form.Item>

                    {/* drop field */}
                    <Form.Item
                        required
                        name="file"
                        shouldUpdate
                        dependencies={['datasetSchemaUpdate', 'override']}
                        valuePropName="value"
                        validateStatus="error"
                        help=""
                        rules={[fileValidator]}
                    >
                        <DragNDrop />
                    </Form.Item>

                    <div className="mb-2">
                        {/* Download Sample */}
                        <Space align="center" className="mr-2">
                            <Button type="link" onClick={() => handleSampleDownload(CSV)}>
                                {downloadConfigs[CSV].name}
                            </Button>
                            <Tooltip placement="top" title={downloadConfigs[CSV].tooltip} showArrow>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                        {/* Download Sample */}
                        {isDataset && (
                            <Space align="center" className="mr-2">
                                <Button type="link" onClick={() => handleSampleDownload(EXCEL)}>
                                    {downloadConfigs[EXCEL].name}
                                </Button>
                                <Tooltip placement="top" title={downloadConfigs[EXCEL].tooltip} showArrow>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Space>
                        )}
                    </div>
                </Form>
                {/* override */}
                <Space align="center" size={1}>
                    <Checkbox
                        checked={override}
                        disabled={isFileProcessing}
                        onChange={(e) => setOverride(e.target.checked)}
                    >
                        Override
                    </Checkbox>
                    <Tooltip
                        overlayStyle={{ maxWidth: '200px' }}
                        placement="top"
                        title="Check this option to generate new data and remove existing entries. Leave it unchecked to merge the new metadata with the existing entries."
                        showArrow
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Space>
            </Modal>
            {isErrorLogModalVisible && (
                <ErrorLogModal onClose={() => setIsErrorLogModalVisible(false)} errorLogs={errorLogs} />
            )}
        </>
    );
}

export default DatasetBulkLoadEntityModal;
