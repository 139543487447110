import { useGetGlossaryTabDetailsQuery } from '../../../../../graphql/glossaryTerm.generated';

// Whether to dynamically load the schema from the backend.
const shouldLoadTerms = (urn) => {
    return !urn;
};

export const useGetGlossaryTerms = (urn) => {
    const {
        data: glossaryTermsData,
        refetch: refetchGlossaryTerms,
        loading: isGlossaryTermsLoading,
    } = useGetGlossaryTabDetailsQuery({
        variables: {
            input: {
                start: 0,
                count: 10000,
                urn,
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        skip: shouldLoadTerms(urn), // Skip query if urn is missing
    });

    return { glossaryTermsData, refetchGlossaryTerms, isGlossaryTermsLoading };
};
