import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import EditStructuredPropertyModal from './EditStructuredPropertyModal';
import { StructuredPropertyEntity, EntityType } from '../../../../../../types.generated';
import { ReactComponent as EditIconOutlined } from '../../../../../../images/icon-edit-outline.svg';

interface Props {
    entityType: EntityType;
    structuredProperty?: StructuredPropertyEntity;
    associatedUrn?: string;
    values?: (string | number | null)[];
    refetch?: () => void;
}

export function EditColumn({ structuredProperty, associatedUrn, values, refetch, entityType }: Props) {
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    if (!structuredProperty || structuredProperty?.definition.immutable) {
        return null;
    }

    const isSupported = structuredProperty?.definition.entityTypes?.some((entity) => entity?.info.type === entityType);
    const tooltipMsg =
        !structuredProperty?.definition.isActive || !isSupported
            ? 'Properties that have been disabled/deleted are no longer available for edit'
            : null;

    return (
        <>
            <Tooltip title={tooltipMsg}>
                <Button
                    type="text"
                    className="icon-button ma-0"
                    disabled={!structuredProperty?.definition.isActive || !isSupported}
                    onClick={() => setIsEditModalVisible(true)}
                    icon={<EditIconOutlined />}
                />
            </Tooltip>
            <EditStructuredPropertyModal
                isOpen={isEditModalVisible}
                structuredProperty={structuredProperty}
                associatedUrn={associatedUrn}
                values={values}
                closeModal={() => setIsEditModalVisible(false)}
                refetch={refetch}
            />
        </>
    );
}
