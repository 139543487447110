import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Menu, message, Tooltip } from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    FolderAddOutlined,
    FolderOpenOutlined,
    GlobalOutlined,
    LinkOutlined,
    MoreOutlined,
    SyncOutlined,
    PlusOutlined,
    CommentOutlined,
    CopyOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { Redirect } from 'react-router';
import Cookies from 'js-cookie';

import { AddOrRemoveGovernanceFlagModal } from './AddOrRemoveGovernanceFlagModal';
import { UpdateGovernanceStatusModal } from './UpdateGovernanceStatusModal';
import CreateGlossaryEntityModal from './CreateGlossaryEntityModal';
import { UpdateDeprecationModal } from './UpdateDeprecationModal';
import MoveGlossaryEntityModal from './MoveGlossaryEntityModal';
import BulkLoadEntityModal from './BulkLoadEntityModal';
import GlossaryCloneModal from './GlossaryCloneModal';
import DeleteDatasetModal from './DeleteDatasetModal';

import { useUpdateDeprecationMutation } from '../../../../graphql/mutations.generated';
import { useCheckModuleEnabledQuery } from '../../../../graphql/app.generated';
import { useGetCommentsQuery } from '../../../../graphql/user.generated';
import useDropdownKeyboard from '../../../hooks/useDropdownKeyboard';

import { getEntityProfileDeleteRedirectPath } from '../../../shared/deleteUtils';
import { shouldDisplayChildDeletionWarning, isDeleteDisabled, isMoveDisabled } from './utils';
import { useUserContext } from '../../../context/useUserContext';
import MoveDomainModal from './MoveDomainModal';
import { useIsNestedDomainsEnabled } from '../../../useAppConfig';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { EntityType } from '../../../../types.generated';
import { CLIENT_AUTH_COOKIE } from '../../../../conf/Global';
import useDeleteEntity from './useDeleteEntity';
import { ANTD_GRAY } from '../constants';

export enum EntityMenuItems {
    COPY_URL,
    UPDATE_DEPRECATION,
    ADD_TERM,
    ADD_TERM_GROUP,
    EXPORT_TERM,
    EXPORT_NODE,
    DELETE,
    BULK_IMPORT,
    MOVE,
    DELETE_DATASET,
    GOVERNANCE_STATUS,
    GOVERNANCE_FLAG,
    ADD_COMMENT,
    CLONE,
}

export const MenuIcon = styled(MoreOutlined)<{ fontSize?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.fontSize || '24'}px;
    height: 32px;
    margin-left: 5px;

    svg {
        width: 24px;
        height: 24px;
    }
`;

const MenuItem = styled.div`
    padding: 0 4px;
    color: #262626;
`;

const StyledMenu = styled(Menu)`
    min-width: 140px !important;
`;

const StyledMenuItem = styled(Menu.Item)<{ disabled: boolean }>`
    ${(props) =>
        props.disabled
            ? `
            ${MenuItem} {
                color: ${ANTD_GRAY[7]};
            }
    `
            : ''}
`;

interface Options {
    hideDeleteMessage?: boolean;
    skipDeleteWait?: boolean;
}

interface Props {
    urn: string;
    entityType: EntityType;
    entityData?: any;
    menuItems: Set<EntityMenuItems>;
    size?: number;
    options?: Options;
    onExportClicked?: () => void;
    refetchForEntity?: () => void;
    refetchForTerms?: () => void;
    refetchForNodes?: () => void;
    onDeleteEntity?: () => void;
    onDeleteDataset?: () => void;
}

function EntityDropdown(props: Props) {
    const {
        urn,
        entityData,
        entityType,
        menuItems,
        refetchForEntity,
        refetchForTerms,
        refetchForNodes,
        onDeleteEntity: onDelete,
        onDeleteDataset,
        size,
        options,
        onExportClicked,
    } = props;

    const me = useUserContext();
    const entityRegistry = useEntityRegistry();
    const actorUrn = Cookies.get(CLIENT_AUTH_COOKIE) || '';

    const { data, loading } = useGetCommentsQuery({ variables: { input: { entity: urn } } });
    const { data: moduleInfo } = useCheckModuleEnabledQuery({ variables: {} });

    const isMessageCenterEnabled = moduleInfo?.checkModuleEnabled?.isEnabled;
    const [updateDeprecation] = useUpdateDeprecationMutation();
    const isNestedDomainsEnabled = useIsNestedDomainsEnabled();
    const { onDeleteEntity, hasBeenDeleted } = useDeleteEntity(
        urn,
        entityType,
        entityData,
        onDelete,
        options?.hideDeleteMessage,
        options?.skipDeleteWait,
    );

    const [isCreateTermModalVisible, setIsCreateTermModalVisible] = useState(false);
    const [isCreateNodeModalVisible, setIsCreateNodeModalVisible] = useState(false);
    const [isBulkImportModalVisible, setIsBulkImportModalVisible] = useState(false);
    const [isDeprecationModalVisible, setIsDeprecationModalVisible] = useState(false);
    const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
    const [isDeleteDatasetModalVisible, setIsDeleteDatasetModalVisible] = useState(false);
    const [isGovernanceFlagModalVisible, setIsGovernanceFlagModalVisible] = useState(false);
    const [isGovernanceModalVisible, setIsGovernanceModalVisible] = useState(false);
    const [isGlossaryCloneModalVisible, setIsGlossaryCloneModalVisible] = useState(false);

    const { visible, setVisible, handleKeyDown, toggleDropdown } = useDropdownKeyboard();

    const handleUpdateDeprecation = async (deprecatedStatus: boolean) => {
        message.loading({ content: 'Updating...' });
        try {
            await updateDeprecation({
                variables: {
                    input: {
                        urn,
                        deprecated: deprecatedStatus,
                        note: '',
                        decommissionTime: null,
                    },
                },
            });
            message.destroy();
            message.success({ content: 'Deprecation Updated', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
            if (e instanceof Error) {
                message.error({ content: `Failed to update Deprecation: \n ${e.message || ''}`, duration: 2 });
            }
        }
        refetchForEntity?.();
    };

    const pageUrl = window.location.href;
    const isGlossaryEntity = entityType === EntityType.GlossaryNode || entityType === EntityType.GlossaryTerm;
    const isDomainEntity = entityType === EntityType.Domain;
    const canCreateGlossaryEntity = !!entityData?.privileges?.canManageChildren;
    const isDomainMoveHidden = !isNestedDomainsEnabled && isDomainEntity;

    /**
     * A default path to redirect to if the entity is deleted.
     */
    const deleteRedirectPath = getEntityProfileDeleteRedirectPath(entityType, entityData);

    // This method checks if current user has commented in the dataset or not
    const hasUserCommented = () => {
        return data?.getComments?.users?.includes(actorUrn);
    };

    const handleButtonClick = () => {
        window.parent.postMessage({ action: 'openNavigationModal', urn, isTempChannel: !hasUserCommented() }, '*');
    };
    const canManageGlossaryEntity = !!entityData?.privileges?.canManageEntity;

    // This method checks if the user can delete the entity
    const getTooltipMsg = () => {
        // If the entity is a glossary entity, check if it has children and if the user can manage the entity
        if (shouldDisplayChildDeletionWarning(entityType, entityData, me.platformPrivileges)) {
            return `Can't delete ${entityRegistry.getEntityName(entityType)} with ${
                isDomainEntity ? 'sub-domain' : 'child'
            } entities.`;
        }

        // If the entity is a glossary entity, check if the user can manage the entity
        if (isDeleteDisabled(entityType, entityData, me.platformPrivileges)) {
            return `You don't have permission to delete this ${entityRegistry.getEntityName(entityType)}.`;
        }

        // If the entity is a domain entity, check if it has children and if the user can manage domains
        return undefined;
    };

    return (
        <>
            <Dropdown
                open={visible}
                overlay={
                    <StyledMenu>
                        {menuItems.has(EntityMenuItems.COPY_URL) && navigator.clipboard && (
                            <Menu.Item key="0">
                                <MenuItem
                                    aria-label="Copy URL"
                                    onClick={() => {
                                        navigator.clipboard.writeText(pageUrl);
                                        message.info('Copied URL!', 1.2);
                                    }}
                                >
                                    <LinkOutlined /> &nbsp; Copy Url
                                </MenuItem>
                            </Menu.Item>
                        )}
                        {menuItems.has(EntityMenuItems.UPDATE_DEPRECATION) && (
                            <Menu.Item key="1">
                                {!entityData?.deprecation?.deprecated ? (
                                    <MenuItem
                                        onClick={() => setIsDeprecationModalVisible(true)}
                                        aria-label="Mark as deprecated"
                                    >
                                        <ExclamationCircleOutlined /> &nbsp; Mark as deprecated
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        onClick={() => handleUpdateDeprecation(false)}
                                        aria-label="Mark as un-deprecated"
                                    >
                                        <ExclamationCircleOutlined /> &nbsp; Mark as un-deprecated
                                    </MenuItem>
                                )}
                            </Menu.Item>
                        )}
                        {menuItems.has(EntityMenuItems.ADD_TERM) && (
                            <StyledMenuItem
                                key="2"
                                disabled={!canCreateGlossaryEntity}
                                onClick={() => setIsCreateTermModalVisible(true)}
                                aria-label="Add Term"
                            >
                                <MenuItem>
                                    <PlusOutlined aria-hidden="true" /> &nbsp;Add Term
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.ADD_TERM_GROUP) && (
                            <StyledMenuItem
                                key="3"
                                disabled={!canCreateGlossaryEntity}
                                onClick={() => setIsCreateNodeModalVisible(true)}
                                aria-label="Add Term Group"
                            >
                                <MenuItem>
                                    <FolderAddOutlined /> &nbsp;Add Term Group
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.BULK_IMPORT) && (
                            <StyledMenuItem
                                data-testid="entity-menu-move-button"
                                key="4"
                                disabled={!canManageGlossaryEntity}
                                onClick={() => setIsBulkImportModalVisible(true)}
                                aria-label="Bulk Import"
                            >
                                <MenuItem>
                                    <PlusOutlined aria-hidden="true" /> &nbsp;Bulk Import
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {!isDomainMoveHidden && menuItems.has(EntityMenuItems.MOVE) && (
                            <StyledMenuItem
                                key="5"
                                disabled={isMoveDisabled(entityType, entityData, me.platformPrivileges)}
                                onClick={() => setIsMoveModalVisible(true)}
                                aria-label="Move"
                            >
                                <MenuItem>
                                    <FolderOpenOutlined /> &nbsp;Move
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.DELETE) && (
                            <Tooltip title={getTooltipMsg()}>
                                <span>
                                    <StyledMenuItem
                                        key="6"
                                        disabled={isDeleteDisabled(entityType, entityData, me.platformPrivileges)}
                                        onClick={onDeleteEntity}
                                        aria-label="Delete"
                                    >
                                        <MenuItem data-testid="entity-menu-delete-button">
                                            <DeleteOutlined /> &nbsp;Delete
                                        </MenuItem>
                                    </StyledMenuItem>
                                </span>
                            </Tooltip>
                        )}
                        {menuItems.has(EntityMenuItems.DELETE_DATASET) && (
                            <StyledMenuItem
                                key="7"
                                disabled={false}
                                onClick={() => setIsDeleteDatasetModalVisible(true)}
                                aria-label="Delete Dataset"
                            >
                                <MenuItem onClick={onDeleteDataset}>
                                    <DeleteOutlined /> &nbsp;Delete Dataset
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.GOVERNANCE_STATUS) && (
                            <StyledMenuItem
                                key="8"
                                disabled={false}
                                onClick={() => setIsGovernanceModalVisible(true)}
                                aria-label="Update Governance Status"
                            >
                                <MenuItem>
                                    <SyncOutlined /> &nbsp;Update Governance Status
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.GOVERNANCE_FLAG) && (
                            <StyledMenuItem
                                key="9"
                                disabled={false}
                                onClick={() => setIsGovernanceFlagModalVisible(true)}
                                aria-label="Add Governance Flag"
                            >
                                <MenuItem>
                                    <GlobalOutlined /> &nbsp;Add Governance Flag
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {isMessageCenterEnabled && menuItems.has(EntityMenuItems.ADD_COMMENT) && (
                            <StyledMenuItem
                                key="10"
                                disabled={loading}
                                onClick={handleButtonClick}
                                aria-label="Comment"
                            >
                                <MenuItem>
                                    <CommentOutlined /> &nbsp; Comment
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.CLONE) && (
                            <StyledMenuItem
                                key="11"
                                disabled={!entityData}
                                onClick={() => setIsGlossaryCloneModalVisible(true)}
                                aria-label="Clone"
                            >
                                <MenuItem>
                                    <CopyOutlined />
                                    &nbsp;Clone
                                </MenuItem>
                            </StyledMenuItem>
                        )}

                        {menuItems.has(EntityMenuItems.EXPORT_TERM) && (
                            <StyledMenuItem key="12" disabled={loading} onClick={onExportClicked} aria-label="Comment">
                                <MenuItem>
                                    <DownloadOutlined style={{ width: 16 }} color="#5F767C" /> &nbsp; Export
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                        {menuItems.has(EntityMenuItems.EXPORT_NODE) && (
                            <StyledMenuItem key="13" disabled={loading} onClick={onExportClicked} aria-label="Comment">
                                <MenuItem>
                                    <DownloadOutlined style={{ width: 16 }} color="#5F767C" /> &nbsp; Export All
                                </MenuItem>
                            </StyledMenuItem>
                        )}
                    </StyledMenu>
                }
                trigger={['click']}
                onOpenChange={(flag) => setVisible(flag)}
            >
                <MenuIcon
                    tabIndex={0}
                    fontSize={size}
                    aria-haspopup="true"
                    aria-label="More options"
                    onClick={toggleDropdown}
                    onKeyDown={handleKeyDown}
                    data-testid="entity-header-dropdown"
                />
            </Dropdown>
            {isCreateTermModalVisible && (
                <CreateGlossaryEntityModal
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsCreateTermModalVisible(false)}
                    refetchData={refetchForTerms}
                />
            )}
            {isCreateNodeModalVisible && (
                <CreateGlossaryEntityModal
                    entityType={EntityType.GlossaryNode}
                    onClose={() => setIsCreateNodeModalVisible(false)}
                    refetchData={refetchForNodes}
                />
            )}
            {isDeprecationModalVisible && (
                <UpdateDeprecationModal
                    urns={[urn]}
                    onClose={() => setIsDeprecationModalVisible(false)}
                    refetch={refetchForEntity}
                />
            )}
            {isBulkImportModalVisible && (
                <BulkLoadEntityModal
                    entityData={entityData}
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsBulkImportModalVisible(false)}
                />
            )}
            {isMoveModalVisible && isGlossaryEntity && (
                <MoveGlossaryEntityModal onClose={() => setIsMoveModalVisible(false)} />
            )}

            {isDeleteDatasetModalVisible && (
                <DeleteDatasetModal urn={urn} onClose={() => setIsDeleteDatasetModalVisible(false)} />
            )}

            {isGovernanceModalVisible && (
                <UpdateGovernanceStatusModal
                    entityData={entityData}
                    refetch={refetchForEntity}
                    onClose={() => setIsGovernanceModalVisible(false)}
                />
            )}
            {isMoveModalVisible && isDomainEntity && <MoveDomainModal onClose={() => setIsMoveModalVisible(false)} />}

            {isGovernanceFlagModalVisible && (
                <AddOrRemoveGovernanceFlagModal
                    entityData={entityData}
                    refetch={refetchForEntity}
                    onClose={() => setIsGovernanceFlagModalVisible(false)}
                />
            )}
            {isGlossaryCloneModalVisible && (
                <GlossaryCloneModal
                    glossaryName={entityData?.properties?.name || ''}
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsGlossaryCloneModalVisible(false)}
                />
            )}
            {hasBeenDeleted && !onDelete && deleteRedirectPath && <Redirect to={deleteRedirectPath} />}
        </>
    );
}

export default EntityDropdown;
