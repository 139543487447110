import React from 'react';
import { Row, Col, Tag } from 'antd';

import StructuredPropertyValue from './StructuredPropertyValue';
import { PropertyRow } from './types';

import { StdDataType, PropertyCardinality } from '../../../../../types.generated';

interface Props {
    propertyRow: PropertyRow;
    filterText?: string;
}

export default function ValuesColumn({ propertyRow, filterText }: Props) {
    const { values, displayName, isEditable, structuredProperty } = propertyRow;
    const isRichText = propertyRow.dataType?.info.type === StdDataType.RichText;

    const isCardinalityMultiple = structuredProperty?.definition?.cardinality === PropertyCardinality.Multiple;
    const isTypeString = structuredProperty?.definition?.valueType?.info.type === StdDataType.String;

    const renderValues = () => {
        if (isCardinalityMultiple && isTypeString && values?.length) {
            const count = (values?.length || 0) - 1;
            const value = values?.[0].value || '-';

            return (
                <Row gutter={4} wrap={false}>
                    <Col flex="none">
                        <Tag className="label-tag grey-tag-light ma-0">{value}</Tag>
                    </Col>
                    {count > 0 && (
                        <Col flex="auto">
                            <Tag className="base-tag label-tag ma-0">+{count}</Tag>
                        </Col>
                    )}
                </Row>
            );
        }

        if (values?.length) {
            return values.map((v) => (
                <StructuredPropertyValue
                    value={v}
                    isRichText={isRichText}
                    filterText={filterText}
                    displayName={displayName}
                    isEditable={isEditable}
                />
            ));
        }

        return <span>&ndash;</span>;
    };

    return <>{renderValues()}</>;
}
