import React from 'react';
import { Pagination } from 'antd';
import styled from 'styled-components';

const PaginationContainer = styled.div`
    padding: 8px;

    .ant-pagination {
        display: flex;
        align-items: center;

        .ant-pagination-total-text {
            order: 1;
            margin: 0;
            flex-grow: 1;
            text-align: right;
        }

        .ant-pagination-options {
            order: -1;
            margin: 0;
            flex-grow: 1;
        }
    }
`;

interface Props {
    page: number;
    totalCount: number;
    loading: boolean;
    pageSize: number;
    onShowSizeChange: any;
    onChangePage: (page: number) => void;
}

export const PaginationBar = ({ page, pageSize, onChangePage, onShowSizeChange, totalCount, loading }: Props) => {
    return (
        <PaginationContainer>
            <Pagination
                total={totalCount}
                current={page}
                pageSize={pageSize}
                showSizeChanger
                disabled={loading}
                pageSizeOptions={[10, 20, 30]}
                showQuickJumper={false}
                className="f-pagination-bar"
                onChange={onChangePage}
                onShowSizeChange={(_currNum, newNum) => onShowSizeChange(newNum)}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            />
        </PaginationContainer>
    );
};
