import React from 'react';
import { Tooltip, Tag } from 'antd';

type CustomTooltipProps = {
    items: any[];
    itemMapper?: any;
    className?: string;
};

const CustomTooltip = ({ items, itemMapper, className = '' }: CustomTooltipProps) => {
    if (!items || items.length <= 1) return null; // Ensure there are multiple items to display

    return (
        <Tooltip
            title={
                <div className="pa-2">
                    {items?.slice(1).map((item) => (
                        <div key={item}>{itemMapper ? itemMapper[item] : item}</div>
                    ))}
                </div>
            }
        >
            <Tag className={`label-tag base-tag ma-0 ${className}`}>{items.length - 1}</Tag>
        </Tooltip>
    );
};

export default CustomTooltip;
