import { Typography, Button } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import Highlight from 'react-highlighter';
import { useGlossaryTermRefetch } from '../../entity/shared/EntityContext';
import { useEntityRegistry } from '../../useEntityRegistry';
import {
    Domain,
    DomainAssociation,
    EntityType,
    GlobalTags,
    GlossaryTerms,
    SubResourceType,
} from '../../../types.generated';
import { EMPTY_MESSAGES, ANTD_GRAY } from '../../entity/shared/constants';
import { DomainLink } from './DomainLink';
import EditTagTermsModal from './AddTagsTermsModal';
import StyledTerm from './term/StyledTerm';
import Tag from './tag/Tag';
import StyledDomain from './domain/Domain';

type Props = {
    uneditableTags?: GlobalTags | null;
    editableTags?: GlobalTags | null;
    editableGlossaryTerms?: GlossaryTerms | null;
    uneditableGlossaryTerms?: GlossaryTerms | null;
    editableDomain?: DomainAssociation | null;
    domain?: Domain | undefined | null;
    canRemove?: boolean;
    canAddTag?: boolean;
    isSidebar?: boolean;
    canAddTerm?: boolean;
    canAddDomain?: boolean;
    showEmptyMessage?: boolean;
    buttonProps?: Record<string, unknown>;
    onOpenModal?: () => void;
    maxShow?: number;
    entityUrn?: string;
    entityType?: EntityType;
    entitySubresource?: string;
    highlightText?: string;
    fontSize?: number;
    refetch?: () => Promise<any>;
    readOnly?: boolean;
};

const NoElementButton = styled(Button)`
    margin-top: 4px;

    :not(:last-child) {
        margin-right: 8px;
    }
`;

const TagText = styled.span`
    color: ${ANTD_GRAY[7]};
    margin: 0 8px;
`;

const TagBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > a {
        max-width: 100%;
    }
`;

const highlightMatchStyle = { background: '#ffe58f', padding: '0' };

export default function TagTermGroup({
    uneditableTags,
    editableTags,
    canRemove,
    canAddTag,
    isSidebar,
    canAddTerm,
    canAddDomain,
    showEmptyMessage,
    buttonProps,
    onOpenModal,
    maxShow,
    uneditableGlossaryTerms,
    editableGlossaryTerms,
    editableDomain,
    domain,
    entityUrn,
    entityType,
    entitySubresource,
    highlightText,
    fontSize,
    refetch,
    readOnly,
}: Props) {
    const entityRegistry = useEntityRegistry();
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalType, setAddModalType] = useState(EntityType.Tag);
    const tagsEmpty = !editableTags?.tags?.length && !uneditableTags?.tags?.length;
    const termsEmpty = !editableGlossaryTerms?.terms?.length && !uneditableGlossaryTerms?.terms?.length;
    const domainEmpty = !editableDomain?.domain?.urn;
    const fetchMore = useGlossaryTermRefetch();

    const defaultInput = {
        variables: {
            start: 0,
            count: 10000,
            urn: entityUrn,
        },
    };

    let renderedTags = 0;

    const refetchGlossaryTerms = (type) => {
        refetch?.();

        // Fetch more glossary terms if the modal was for adding glossary terms
        if (type === EntityType.GlossaryTerm) {
            fetchMore?.(defaultInput);
        }
    };

    // To avoid duplication of the add term button in the sidebar
    const renderAddTermButton = (type) => {
        if (canAddTerm && !readOnly) {
            return (
                <Button
                    type={showEmptyMessage && termsEmpty ? 'default' : type}
                    onClick={() => {
                        setAddModalType(EntityType.GlossaryTerm);
                        setShowAddModal(true);
                    }}
                    className={`${isSidebar ? 'mt-3' : ''}`}
                    style={{ maxWidth: 'fit-content' }}
                    aria-label="Add Terms"
                >
                    <PlusOutlined />
                    <span>Add Terms</span>
                </Button>
            );
        }
        return <></>;
    };

    const closeTagTermModal = (type) => {
        onOpenModal?.();
        setShowAddModal(false);
        refetchGlossaryTerms(type);
    };

    return (
        <>
            <TagBox className="f-tag-list-wrap f-layout-wrap-xs">
                {domain && (
                    <DomainLink domain={domain} name={entityRegistry.getDisplayName(EntityType.Domain, domain) || ''} />
                )}
                {editableDomain?.domain?.urn && (
                    <StyledDomain
                        domain={editableDomain}
                        entityUrn={entityUrn}
                        entitySubresource={entitySubresource}
                        canRemove={canRemove}
                        readOnly={readOnly}
                        onOpenModal={onOpenModal}
                        refetch={refetch}
                        fontSize={fontSize}
                    />
                )}
                {uneditableGlossaryTerms?.terms?.map((term) => {
                    renderedTags += 1;
                    if (maxShow && renderedTags === maxShow + 1)
                        return (
                            <TagText className="f-color-high-contrast" tabIndex={0}>
                                <Highlight matchStyle={highlightMatchStyle} search={highlightText}>
                                    {uneditableGlossaryTerms?.terms
                                        ? `+${uneditableGlossaryTerms?.terms?.length - maxShow}`
                                        : null}
                                </Highlight>
                            </TagText>
                        );
                    if (maxShow && renderedTags > maxShow) return null;

                    return (
                        <StyledTerm
                            term={term}
                            entityUrn={entityUrn}
                            entitySubresource={entitySubresource}
                            canRemove={false}
                            readOnly={readOnly}
                            highlightText={highlightText}
                            onOpenModal={onOpenModal}
                            refetch={refetch}
                            fontSize={fontSize}
                        />
                    );
                })}

                {editableGlossaryTerms?.terms?.map((term) => (
                    <StyledTerm
                        term={term}
                        entityUrn={entityUrn}
                        entitySubresource={entitySubresource}
                        canRemove={canRemove}
                        readOnly={readOnly}
                        highlightText={highlightText}
                        onOpenModal={onOpenModal}
                        refetch={() => refetchGlossaryTerms(EntityType.GlossaryTerm)}
                        fontSize={fontSize}
                    />
                ))}
                {/* uneditable tags are provided by ingestion pipelines exclusively */}
                {uneditableTags?.tags?.map((tag) => {
                    renderedTags += 1;
                    if (maxShow && renderedTags === maxShow + 1)
                        return (
                            <TagText className="f-color-high-contrast">
                                {uneditableTags?.tags ? `+${uneditableTags?.tags?.length - maxShow}` : null}
                            </TagText>
                        );
                    if (maxShow && renderedTags > maxShow) return null;

                    return (
                        <Tag
                            tag={tag}
                            entityUrn={entityUrn}
                            entitySubresource={entitySubresource}
                            canRemove={false}
                            readOnly={readOnly}
                            highlightText={highlightText}
                            onOpenModal={onOpenModal}
                            refetch={refetch}
                            className="f-truncate-100"
                            fontSize={fontSize}
                        />
                    );
                })}
                {/* editable tags may be provided by ingestion pipelines or the UI */}
                {editableTags?.tags?.map((tag) => {
                    renderedTags += 1;
                    if (maxShow && renderedTags > maxShow) return null;

                    return (
                        <Tag
                            tag={tag}
                            entityUrn={entityUrn}
                            entitySubresource={entitySubresource}
                            canRemove={canRemove}
                            readOnly={readOnly}
                            highlightText={highlightText}
                            onOpenModal={onOpenModal}
                            refetch={refetch}
                            className="pri-tag"
                            fontSize={fontSize}
                        />
                    );
                })}
                {showEmptyMessage && canAddTag && tagsEmpty && (
                    <Typography.Paragraph className="f-text-content f-color-dark-black-s80">
                        {EMPTY_MESSAGES.tags.title}. {EMPTY_MESSAGES.tags.description}
                    </Typography.Paragraph>
                )}
                {showEmptyMessage && canAddTerm && termsEmpty && (
                    <Typography.Paragraph className="f-text-content f-color-dark-black-s80">
                        {EMPTY_MESSAGES.terms.title}. {EMPTY_MESSAGES.terms.description}
                    </Typography.Paragraph>
                )}
                {showEmptyMessage && canAddDomain && domainEmpty && (
                    <Typography.Paragraph className="f-text-content f-color-dark-black-s80">
                        {EMPTY_MESSAGES.terms.title}. {EMPTY_MESSAGES.terms.description}
                    </Typography.Paragraph>
                )}
                {canAddTag && !readOnly && (
                    <NoElementButton
                        type={showEmptyMessage && tagsEmpty ? 'default' : 'link'}
                        aria-label="Add Tags"
                        onClick={() => {
                            setAddModalType(EntityType.Tag);
                            setShowAddModal(true);
                        }}
                        {...buttonProps}
                    >
                        <PlusOutlined />
                        <span>Add Tags</span>
                    </NoElementButton>
                )}

                {!isSidebar && renderAddTermButton('link')}

                {canAddDomain && !readOnly && (
                    <NoElementButton
                        type={showEmptyMessage && domainEmpty ? 'default' : 'link'}
                        onClick={() => {
                            setAddModalType(EntityType.Domain);
                            setShowAddModal(true);
                        }}
                        {...buttonProps}
                        aria-label="Set Domain"
                    >
                        <PlusOutlined />
                        <span>Set Domain</span>
                    </NoElementButton>
                )}

                {showAddModal && !!entityUrn && !!entityType && (
                    <EditTagTermsModal
                        type={addModalType}
                        visible
                        onCloseModal={() => closeTagTermModal(addModalType)}
                        resources={[
                            {
                                resourceUrn: entityUrn,
                                subResource: entitySubresource,
                                subResourceType: entitySubresource ? SubResourceType.DatasetField : null,
                            },
                        ]}
                    />
                )}
            </TagBox>

            {isSidebar && renderAddTermButton('text')}
        </>
    );
}
