import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Row, Col, Tag, Input, Checkbox, Space, Divider, message, Dropdown, Menu, Modal } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { glossaryFilters } from '../../containers/profile/FilterOptions/FilterOptions';
import { ReactComponent as MoreVertIcon } from '../../../../../images/more-vert.svg';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import EditTagTermsModal from '../../../../shared/tags/AddTagsTermsModal';
import { BulkAction } from '../../../../shared/bulkAction/BulkAction';
import { StyledTable } from '../../components/styled/StyledTable';
import CustomAvatar from '../../../../shared/avatar/CustomAvatar';
import CustomTooltip from '../../components/common/CustomTooltip';
import MetaDataPopup, { getStatusClass } from './MetaDataPopup';
import { PaginationBar } from './PaginationBar';

import { useBatchRemoveTermsMutation } from '../../../../../graphql/mutations.generated';
import { useGetEntitiesLazyQuery } from '../../../../../graphql/entity.generated';
import { useEntityRegistry } from '../../../../useEntityRegistry';
import { useEntityData, useRefetch, useGlossaryTermRefetch, useGlossaryTermData } from '../../EntityContext';
import { handleBatchError } from '../../utils';

import { EntityType } from '../../../../../types.generated';

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: right;
`;

const StyledMenu = styled(Menu)`
    min-width: 148px !important;
    border-radius: 4px;
    padding: 0;

    .ant-dropdown-menu-item {
        color: #4c4e54;
        padding: 8px 12px !important;
    }
`;

export const GlossaryTermsTab = () => {
    const { entityData } = useEntityData();
    const entityRegistry = useEntityRegistry();

    const fetchMore = useGlossaryTermRefetch();
    const refetch = useRefetch();

    const { glossaryTermsData: data, isGlossaryTermsLoading: loading } = useGlossaryTermData();
    const [urnMapper, setUrnMapper] = useState<Record<string, string>>({});
    const [selectedItems, setSelectedItems]: any = useState([]);
    const [filterOptions, setFilterOptions]: any = useState([]);
    const [glossaryTerms, setGlossaryTerms]: any = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const defaultInput = {
        start: 0,
        count: 10000,
        urn: entityData?.urn,
    };

    const [getEntities, { data: entityDetails, loading: isEntityLoading }] = useGetEntitiesLazyQuery();
    const [batchRemoveTermsMutation] = useBatchRemoveTermsMutation();

    const getFilteredGlossaryTerms = (searchValue) => {
        return data?.getGlossaryTabDetails?.terms?.filter((term) => {
            const termName = term?.name.toLowerCase() ?? '';

            // Ensure all filter conditions are met
            const passesFilters = filterOptions.every(
                (filter) => term?.[filter.field]?.includes(filter.values[0]), // Consider all values if needed
            );

            return termName.includes(searchValue.toLowerCase()) && passesFilters;
        });
    };

    useEffect(() => {
        // Fetches the entities for the glossary terms
        const fetchEntities = (terms) => {
            const ownerURNs = terms?.reduce((acc, current) => (current.owners ? acc.concat(current.owners) : acc), []);
            const domainURNs = terms?.flatMap((item) => item.domains);
            const dataProductURNs = terms?.flatMap((item) => item.dataProducts);
            const glossaryTermURNs = terms?.map((item) => item.urn);

            getEntities({
                variables: {
                    urns: [...domainURNs, ...dataProductURNs, ...ownerURNs, ...glossaryTermURNs],
                },
            });
        };

        if (data?.getGlossaryTabDetails?.terms?.length) {
            const terms = data?.getGlossaryTabDetails?.terms;

            fetchEntities(terms);

            const filteredTerms = getFilteredGlossaryTerms(searchText);

            setGlossaryTerms(filteredTerms);
        } else {
            setGlossaryTerms([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.getGlossaryTabDetails?.terms, getEntities]);

    // Fetches the glossary terms on refetch
    const generateDataGrid = () => {
        return (glossaryTerms || [])
            .map((term: any) => {
                return {
                    ...term,
                    subType: term.subTypes?.[0] || '-',
                };
            })
            .slice((page - 1) * pageSize, page * pageSize);
    };

    // Fetches the glossary terms
    const refetchGridListing = () => {
        setTimeout(() => {
            fetchMore?.({
                variables: {
                    input: defaultInput,
                },
            });
            refetch();
        }, 2000);
    };

    const getEntityDetailsByType = (entity) => {
        const displayNameResolvers = {
            [EntityType.CorpUser]: entity.properties?.fullName || 'System',
            [EntityType.GlossaryTerm]: entity?.parentNodes?.nodes,
        };

        return displayNameResolvers[entity.type] || entity.properties?.name || entity.properties?.fullName || '';
    };

    const generateUrnMapper = useCallback(
        (prevUrnMapper: Record<string, string> = {}) => {
            if (!entityDetails?.entities?.length) return prevUrnMapper; // Keep old data if no new entities

            // Generate the urn mapper for the glossary terms and their related entities (domains, data products, owners)
            return entityDetails.entities.reduce(
                (acc: Record<string, string>, entity: any) => {
                    acc[entity.urn] = getEntityDetailsByType(entity);
                    return acc;
                },
                { ...prevUrnMapper },
            ); // Merge old data with new
        },
        [entityDetails],
    );

    useEffect(() => {
        setUrnMapper((prev) => generateUrnMapper(prev));
    }, [generateUrnMapper]);

    const handleClick = (record) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(record.urn)
                ? prevSelected.filter((urn) => urn !== record.urn)
                : [...prevSelected, record.urn],
        );
    };

    const handleSelectAll = () => {
        if (selectedItems.length === glossaryTerms.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(glossaryTerms.map((term) => term.urn));
        }
    };

    const batchRemoveTerms = (urn = '') => {
        const msg = urn ? 'Term' : 'Terms';

        Modal.confirm({
            title: `Do you want to remove ${msg}?`,
            content: `Are you sure you want to remove ${msg}?`,
            onOk() {
                batchRemoveTermsMutation({
                    variables: {
                        input: {
                            termUrns: urn ? [urn] : selectedItems,
                            resources: [
                                {
                                    resourceUrn: entityData?.urn || '',
                                },
                            ],
                        },
                    },
                })
                    .then(({ errors }) => {
                        if (!errors) {
                            setPage(1);
                            message.success({
                                content: `Removed ${msg} Successfully`,
                                duration: 2,
                            });
                        }
                    })
                    .catch((e) => {
                        message.destroy();
                        message.error(
                            handleBatchError(selectedItems, e, {
                                content: `Failed to remove: \n ${e.message || ''}`,
                                duration: 3,
                            }),
                        );
                    })
                    .finally(() => {
                        refetchGridListing();
                        setSelectedItems([]);
                    });
            },
            onCancel() {},
            okText: 'Yes',
            className: 'f-borderless-modal',
            okButtonProps: {
                type: undefined,
                danger: true,
            },
            autoFocusButton: null,
            maskClosable: true,
            centered: true,
        });
    };

    const removeRelationship = (urn) => batchRemoveTerms(urn);

    const glossaryGird = [
        {
            width: '4%',
            title: (
                <Checkbox
                    aria-label="Select All"
                    onClick={handleSelectAll}
                    disabled={!glossaryTerms.length}
                    checked={glossaryTerms.length > 0 && selectedItems.length === glossaryTerms.length}
                    indeterminate={selectedItems.length > 0 && selectedItems.length < glossaryTerms.length}
                />
            ),
            ariaLabel: 'Select All',
            dataIndex: 'selected',
            key: 'selected',
            render: (_, record) => {
                return (
                    <Checkbox
                        className="ml-2"
                        aria-label="Select this Row"
                        checked={selectedItems.includes(record?.urn)}
                        onClick={() => handleClick(record)}
                    />
                );
            },
        },
        {
            width: '35%',
            title: 'Term Name',
            ariaLabel: 'Term Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                const breadcrumb: any = urnMapper[record.urn] || [];
                const themeColor = getStatusClass(record.governanceStatus);
                const url = entityRegistry.getEntityUrl(EntityType.GlossaryTerm, record.urn);

                return (
                    <>
                        <Row gutter={8} align="middle" className="mx-0">
                            <Col flex="none">
                                <Link
                                    className="f-text-medium-content f-text-single-line f-text-semibold f-color-dark-black-s80"
                                    role="button"
                                    tabIndex={0}
                                    to={url}
                                >
                                    {record?.name}
                                </Link>
                            </Col>
                            {record.governanceStatus && (
                                <Col flex="none">
                                    <Tag className={`f-small label-tag ${themeColor} ma-0`}>
                                        {record.governanceStatus?.replace(/_/g, ' ')}
                                    </Tag>
                                </Col>
                            )}
                        </Row>
                        <div className="f-text-medium-content f-color-dark-black-s50">
                            {breadcrumb.map((item) => `${item.properties.name} / `)}
                        </div>
                    </>
                );
            },
        },
        {
            width: '15%',
            title: 'SubTypes',
            ariaLabel: 'SubType',
            dataIndex: 'subType',
            key: 'subType',
        },
        {
            width: '15%',
            title: 'Domain',
            ariaLabel: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            render: (_, record) => <>{urnMapper[record.domains[0]] || '-'}</>,
        },
        {
            width: '15%',
            title: 'Data Product',
            ariaLabel: 'Data Product',
            dataIndex: 'dataProduct',
            key: 'dataProduct',
            render: (_, record) => {
                const dataProductUrn = record?.dataProducts?.[0];

                return (
                    <Row wrap={false} gutter={4} align="middle" className="mx-0">
                        <Col flex="none">
                            <Tag className="label-tag grey-tag-light ma-0">{urnMapper[dataProductUrn] || '-'}</Tag>
                        </Col>
                        {record?.dataProducts.length > 1 && (
                            <Col flex="none">
                                <CustomTooltip items={record?.dataProducts} itemMapper={urnMapper} />
                            </Col>
                        )}
                    </Row>
                );
            },
        },
        {
            title: 'Owner',
            ariaLabel: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            render: (_, record) => {
                const user = record.owners[0];

                return record.owners.length ? (
                    <Row wrap={false} align="middle" className="mx-0">
                        <Col flex="none">
                            <CustomAvatar name={urnMapper[user]} size={24} />
                        </Col>
                        {record.owners.length > 1 && (
                            <Col flex="none">
                                <CustomTooltip items={record?.owners} itemMapper={urnMapper} />
                            </Col>
                        )}
                    </Row>
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            align: 'right',
            title: '',
            render: (_, record) => (
                <Row gutter={8} wrap={false} align="middle" justify="end" className="mx-0">
                    <Col flex="none">
                        <MetaDataPopup urnMapper={urnMapper} metaData={record} />
                    </Col>
                    <Col flex="none">
                        <ActionButtonContainer>
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <StyledMenu>
                                        <Menu.Item
                                            key="remove-relationship"
                                            onClick={() => removeRelationship(record.urn)}
                                        >
                                            Remove Relationship
                                        </Menu.Item>
                                    </StyledMenu>
                                }
                            >
                                <Button type="text" className="icon-button ma-0" icon={<MoreVertIcon />} />
                            </Dropdown>
                        </ActionButtonContainer>
                    </Col>
                </Row>
            ),
        },
    ];

    const closeTermModal = () => {
        setShowAddModal(false);
    };

    const onChangePage = (p) => {
        setPage(p);
    };

    const onShowSizeChange = (newPage) => {
        setPageSize(newPage);
        onChangePage(1);
    };

    const isFilterMatching = (filter, term) => {
        // Check if the filter field exists in the term
        const terms = term[filter.field].map((item) => item?.toLowerCase());

        // Check if the term contains the filter field and its value
        return terms?.includes(filter.values[0].toLowerCase());
    };

    // Apply filters to the glossary terms based on the selected filters
    const applyFilters = (updatedFilters) => {
        const filteredGlossaryTerms = data?.getGlossaryTabDetails?.terms.filter((term: any) => {
            const termName = term.name?.toLowerCase() ?? '';

            // Ensure all filter conditions are met for each term in the glossary terms
            return (
                updatedFilters.every((filter) => isFilterMatching(filter, term)) &&
                (searchText === '' || termName.includes(searchText.toLowerCase())) // Ensure search works correctly
            );
        });

        setGlossaryTerms(filteredGlossaryTerms ?? []);
        setPage(1);
    };

    const onApplyFilter = (type, urn) => {
        const filterExists = filterOptions.some((filter) => filter.field === type);

        // Update the filter options based on the selected filter type and value
        const updatedFilters = filterExists
            ? filterOptions.map((filter) => (filter.field === type ? { ...filter, values: [urn] } : filter))
            : [...filterOptions, { field: type, values: [urn] }];

        setFilterOptions(updatedFilters);
        applyFilters(updatedFilters);
    };

    const onResetFilter = (type: string) => {
        const updatedFilters = filterOptions.filter((filter) => filter.field !== type);

        setFilterOptions(updatedFilters);
        applyFilters(updatedFilters);
    };

    const getRowKey = (glossaryTerm) => glossaryTerm?.urn || glossaryTerm?.name;

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchText(searchValue);

        if (!data?.getGlossaryTabDetails?.terms) return;

        const filteredGlossaryTerms = getFilteredGlossaryTerms(searchValue);

        setGlossaryTerms(filteredGlossaryTerms);
    };

    return (
        <>
            <Card>
                <Row gutter={8} className="px-3 pt-2 mx-0">
                    <Col flex="auto">
                        <Input
                            size="middle"
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            value={searchText}
                            onChange={onSearch}
                        />
                    </Col>
                    <Col flex="none">
                        <Button type="primary" className="f-btn-underline" onClick={() => setShowAddModal(true)}>
                            <PlusOutlined />
                            <span>Add Term</span>
                        </Button>
                    </Col>
                </Row>
                <Space className="px-2 py-3 f-layout-wrap-xs" size="small">
                    {glossaryFilters.map((item) => (
                        <FilterDropdown
                            {...item}
                            key={item.id}
                            disabled={!data?.getGlossaryTabDetails?.terms.length}
                            onApplyFilter={onApplyFilter}
                            onResetFilter={onResetFilter}
                        />
                    ))}
                </Space>
                <StyledTable
                    size="small"
                    rowKey={getRowKey}
                    loading={loading || isEntityLoading}
                    pagination={false}
                    dataSource={generateDataGrid()}
                    // typescript is complaining that default sort order is not a valid column field- overriding this here
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    columns={glossaryGird}
                />
                <Divider className="ma-0" />
                <PaginationBar
                    page={page}
                    loading={loading || false}
                    pageSize={pageSize}
                    totalCount={glossaryTerms.length}
                    onChangePage={onChangePage}
                    onShowSizeChange={onShowSizeChange}
                />
            </Card>

            <BulkAction
                hideExport
                type="glossaryTermTab"
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                onRemove={() => batchRemoveTerms()}
            />

            {showAddModal && (
                <EditTagTermsModal
                    type={EntityType.GlossaryTerm}
                    visible
                    onCloseModal={closeTermModal}
                    onSuccess={() => {
                        closeTermModal();
                        refetchGridListing();
                    }}
                    resources={[
                        {
                            resourceUrn: entityData?.urn || '',
                        },
                    ]}
                />
            )}
        </>
    );
};
